import { functions } from '@/config/firebase.config';

export const searchRecordLabels = (query) => {
  return Promise.resolve([
    {
      id: '1',
      label_studio: 'HighBridge Company',
    },
    {
      id: '2',
      label_studio: 'Adelphi Records',
    },
    {
      id: '3',
      label_studio: 'Universal Music Group',
    },
    {
      id: '4',
      label_studio: 'RCA Victor',
    },
    {
      id: '5',
      label_studio: 'BMG Music',
    },
  ]);
  // return functions
  // .httpsCallable('searchRecordLabels')({
  //   query,
  // })
  // .then((res) => {
  //   return res.data.data;
  // });
};
