<template>
  <div>
    <span :class="$style.filterGroupLabel">
      <template v-if="selectedCount">{{ selectedCount }}</template>
      {{ " " + label }}</span
    >
    <ul :class="$style.filtersList">
      <li
        v-for="(option, idx) of options"
        :key="option.id"
        :class="$style.filtersListItem"
      >
        <div>
          <label>
            <input
              :class="$style.checkbox"
              type="checkbox"
              :checked="option.checked"
              @change="onFilterChange($event, idx)"
            />

            <span>{{ option[labelKey] }}</span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FilterGroup",
  props: {
    filterType: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    labelKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedCount() {
      return this.options.reduce(
        (acc, { checked }) => acc + (checked ? 1 : 0),
        0
      );
    },
  },
  methods: {
    onFilterChange(evt, idx) {
      this.$emit("change", {
        filterType: this.filterType,
        idx,
        checked: evt.target.checked,
      });
    },
  },
};
</script>

<style lang="scss" module>
.filterGroupLabel {
  color: $color-primary;
  font-weight: 500;
  font-family: $primary-font;
}

.filtersList {
  padding: 0;
  list-style-type: none;
}

.filtersListItem {
}

.filtersListItem + .filtersListItem {
  margin-top: 0.5rem;
}

.checkbox {
  margin-right: 0.75rem;
  padding: 0.5rem;
}
</style>