<template>
  <div>
    <FilterGroup
      :class="$style.filterGroup"
      filterType="rightsTypes"
      label="Rights Types"
      labelKey="label"
      :options="rightsTypes"
      @change="updateFilterById"
    />
    <FilterGroup
      :class="$style.filterGroup"
      filterType="recordLabels"
      label="Record Labels"
      labelKey="label_studio"
      :options="recordLabels"
      @change="updateFilterById"
    />
    <FilterGroup
      :class="$style.filterGroup"
      filterType="publishers"
      label="Music Publishers"
      labelKey="publisher"
      :options="publishers"
      @change="updateFilterById"
    />
  </div>
</template>

<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { IDLE, PENDING, SUCCESS, ERROR } from "@/constants/apiStatus";
import { withAsync } from "@/helpers";
import { searchRecordLabels } from "@/api/recordLabelApi.js";
import { searchPublishers } from "@/api/publisherApi";
import FilterGroup from "./FilterGroup";
export default {
  name: "Filters",
  components: {
    FilterGroup,
  },
  data() {
    return {
      recordLabels: [],
      publishers: [],
      rightsTypes: [
        {
          id: 1,
          label: "Mechanical",
          checked: false,
        },
        {
          id: 2,
          label: "Performance",
          checked: false,
        },
        {
          id: 3,
          label: "Sync",
          checked: false,
        },
      ],
      fetchRecordLabelsStatus: IDLE,
      fetchPublishersStatus: IDLE,
      fetchFiltersStatus: IDLE,
    };
  },
  created() {
    this.fetchFilters();
  },
  computed: {
    ...apiStatusComputedFactory([
      "fetchRecordLabelsStatus",
      "fetchPublishersStatus",
      "fetchFiltersStatus",
    ]),
  },
  methods: {
    /**
     * @public
     * This is a public method that is used by the parent via a ref
     */
    getCurrentFilters() {
      return this.prepareFilters(this.$data);
    },
    updateFilterById({ filterType, idx, checked }) {
      this.$set(this[filterType][idx], "checked", checked);
      this.emitFiltersChangeEvent();
    },
    getSelectedFilters(filter) {
      let selectedFilters = [];
      for (const item of filter) {
        if (item.checked) {
          selectedFilters.push(item);
        }
      }

      return selectedFilters;
    },
    prepareFilters({ recordLabels, publishers, rightsTypes }) {
      return {
        recordLabels: this.getSelectedFilters(recordLabels),
        publishers: this.getSelectedFilters(publishers),
        rightsTypes: this.getSelectedFilters(rightsTypes),
      };
    },
    emitFiltersChangeEvent() {
      const { recordLabels, publishers, rightsTypes } = this;
      this.$emit(
        "change",
        this.prepareFilters({
          recordLabels,
          publishers,
          rightsTypes,
        })
      );
    },
    async fetchFilters() {
      this.fetchFiltersStatus = PENDING;
      const { error } = await withAsync(() =>
        Promise.all([this.searchRecordLabels(""), this.searchPublishers("")])
      );

      if (error) {
        this.fetchFiltersStatus = ERROR;
        return;
      }
      this.emitFiltersChangeEvent();
      this.fetchFiltersStatus = SUCCESS;
    },
    async searchRecordLabels(query) {
      this.fetchRecordLabelsStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchRecordLabels(query)
      );
      if (error) {
        this.fetchRecordLabelsStatus = ERROR;
        return;
      }
      this.recordLabels = response.map((item) => {
        item.checked = false;
        return item;
      });
      this.fetchRecordLabelsStatus = SUCCESS;
    },
    async searchPublishers(query) {
      this.fetchPublishersStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchPublishers(query)
      );
      if (error) {
        this.fetchPublishersStatus = ERROR;
        return;
      }
      this.publishers = response.map((item) => {
        item.checked = false;
        return item;
      });
      this.fetchPublishersStatus = SUCCESS;
    },
  },
};
</script>

<style lang="scss" module>
.filterGroup + .filterGroup {
  margin-top: 2rem;
}
</style>