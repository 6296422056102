import { functions } from '@/config/firebase.config';

export const searchPublishers = (query) => {
  return Promise.resolve([
    {
      id: '1',
      publisher: 'W B Music Corp',
    },
    {
      id: '2',
      publisher: 'Universal Music Corp.',
    },
    {
      id: '3',
      publisher: 'Bmg Platinum Songs (US)',
    },
    {
      id: '4',
      publisher: 'Bmg Gold Songs',
    },
    {
      id: '5',
      publisher: 'Universal Music Corporation',
    },
  ]);
  //   return functions
  //     .httpsCallable('searchPublishers')({
  //       query,
  //     })
  //     .then((res) => {
  //       return res.data.data;
  //     });
};
